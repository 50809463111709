import Client from '@/api_client/client.js'

const batchUpsert = ({ apiKey, hrFlowId, params }) => {
  const BATCH_UPSERT_HR_FLOW_STEP = `/accounts/${apiKey}/hr_flows/${hrFlowId}/hr_flow_steps/batch_upsert`
  return Client.put(BATCH_UPSERT_HR_FLOW_STEP, params)
}

export {
  batchUpsert
}
