<template>
  <v-row v-if="isLoading" align="start" justify="start">
    <v-col
      v-for="i in 3" :key="i" cols="12"
      class="mb-4"
    >
      <v-skeleton-loader type="card" elevation="2" />
    </v-col>
  </v-row>
  <v-row v-else align="start" justify="start">
    <v-col v-for="hrFlow in hrFlowsList" :key="hrFlow.id" cols="12">
      <single-hr-flow
        class="mb-2" :hr-flow="hrFlow"
        @edit="onEdit"
        @delete="onDelete"
      />
    </v-col>
    <v-col v-if="showLoadMorePages" v-intersect.once="onIntersect" />
    <v-row v-if="isLoadingNextPage" align="start" justify="start">
      <v-col
        v-for="i in 3" :key="i" cols="12"
        class="mb-4"
      >
        <v-skeleton-loader type="card" elevation="2" />
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import SingleHrFlow from '@/components/hr_flows/SingleHrFlow.vue'
import useHrFlow from '@/composables/useHrFlow.js'

import { computed } from 'vue'
export default {
  components: {
    'single-hr-flow': SingleHrFlow
  },
  props: {
    apiKey: {
      type: String,
      required: true
    }
  },
  setup (props, { emit }) {
    const {
      hrFlowsList, isLoading, isLoadingNextPage, pagination,
      loadHrFlows, loadNextPage
    } = useHrFlow(props.apiKey)

    // Methods
    const onEdit = (hrFlow) => emit('edit', hrFlow)
    const onDelete = (hrFlow) => emit('delete', hrFlow)
    const onIntersect = loadNextPage

    const showLoadMorePages = computed(() => {
      if (isLoading.value || isLoadingNextPage.value) return false
      return pagination.value.total > hrFlowsList.value.length
    })

    loadHrFlows()
    return {
      isLoading,
      hrFlowsList,
      showLoadMorePages,
      isLoadingNextPage,
      onEdit,
      onDelete,
      onIntersect
    }
  }
}
</script>
