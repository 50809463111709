
import { index as indexHrFlows, destroy as destroyHrFlow } from '@/api_client/HrFlow.js'
import { isString } from '@/helpers/Utils.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'
import { ref } from 'vue'

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 80
const defaultPagination = () => ({ page: DEFAULT_PAGE, perPage: DEFAULT_PER_PAGE, total: null })

const isLoading = ref(false)
const isLoadingNextPage = ref(false)
const pagination = ref(defaultPagination())
const hrFlowsList = ref([])
let currentApiKey = null
export default function useHrFlow (apiKey) {
  const resetValues = () => {
    isLoading.value = false
    isLoadingNextPage.value = false
    pagination.value = defaultPagination()
    hrFlowsList.value = []
  }

  if (isString(apiKey) && currentApiKey !== apiKey) {
    resetValues()

    currentApiKey = apiKey
  }

  const setPaginationFromResponse = (responseMeta) => {
    const paginationVal = pagination.value
    paginationVal.total = responseMeta.total
    paginationVal.page = responseMeta.page
    paginationVal.perPage = responseMeta.per_page
  }

  // Methods

  const loadHrFlows = ({ reset = false } = {}) => {
    if (reset === true) {
      resetValues()
    }
    if (pagination.value.page === DEFAULT_PAGE) {
      isLoading.value = true
    } else {
      isLoadingNextPage.value = true
    }
    const requestParams = {
      page: pagination.value.page,
      per_page: pagination.value.perPage
    }

    return indexHrFlows(apiKey, requestParams)
      .then(({ data: { data, meta } }) => {
        setPaginationFromResponse(meta)
        hrFlowsList.value = hrFlowsList.value.concat(data)
      })
      .catch(errHandler)
      .finally(() => {
        isLoading.value = false
        isLoadingNextPage.value = false
      })
  }
  const loadNextPage = () => {
    pagination.value.page++
    loadHrFlows()
  }

  const deleteHrFlow = (hrFlow) => {
    return destroyHrFlow(apiKey, hrFlow.id)
      .then(() => loadHrFlows({ reset: true }))
  }

  return {
    isLoading,
    isLoadingNextPage,
    hrFlowsList,
    pagination,
    loadNextPage,
    loadHrFlows,
    deleteHrFlow
  }
}
