export default {
  save: 'Guardar',
  cancel: 'Cancelar',
  ok: 'De acuerdo',
  delete: 'Eliminar',
  no_result: 'No hay resultados',
  loading: 'Cargando',
  exect_date: 'Tareas de fecha',
  daily_operations: 'Tareas diarias',
  shift_start: 'Inicio del turno',
  shift_middle: 'Mitad del turno',
  shift_end: 'Fin del turno',
  shift_transfer: 'Traslado de turno',
  during_shift: 'Durante el turno',
  currnent_day: '{dayString}',
  close: 'Cerrar',
  select_all: 'Seleccionar todo',
  add: 'Agregar',
  yes: 'Sí',
  no: 'No',
  title: 'Título',
  report: 'Informe',
  create: 'Crear',
  edit: 'Editar',
  faults: 'Tickets',
  area: 'Área',
  name: 'nombre',
  reset: 'Reiniciar',
  all: 'Todo',
  employee: 'Empleados',
  departments: 'Departamentos',
  account_name: 'Nombre de la cuenta',
  time: 'Tiempo',
  today: 'Hoy',
  yesterday: 'Ayer',
  this_week: 'Esta semana',
  older: 'Más viejo',
  tasks: 'Tareas',
  one_task: 'Una tarea',
  chat: 'Chat',
  menu: 'Menú',
  download: 'Descargar',
  copy: 'Duplicar',
  permissions: 'Permisos',
  email: 'Correo electrónico',
  notifications: 'Notificaciones',
  community: 'Comunidad',
  file_attach: 'Agregar archivo',
  comments: 'Comentarios',
  comment: 'Comentario',
  comment_1: 'Comentario',
  share: 'Compartir',
  show_more_details: 'Más detalles',
  show_more: 'Mostrar más',
  view: 'Ver',
  info: 'Información',
  total: 'Total',
  done: 'Hecho',
  not_done: 'No realizadas',
  untagged: 'Sin etiquetar',
  completed: 'Completado',
  on_going: 'En curso',
  from: 'De:',
  to: 'Hasta:',
  next: 'NEXT',
  back: 'atrás',
  optional: 'Opcional *',
  reset_all: 'Restablecer todo',
  x_selected: '{count} seleccionados',
  hours: 'Horas',
  x_hours: '%{hours} hras',
  x_hour_and_minutes: '%{hours} hras %{minutes} min',
  x_minutes: '%{minutes} min',
  x_days: '%{days} days',
  x_months: '%{months} meses',
  x_years: '%{years} años',
  new: 'Nuevo',
  date: 'Fecha',
  without_category: 'Sin categoria',
  description: 'Descripción',
  photos: 'Fotografias',
  video: 'Vídeo',
  add_file: 'Agregar archivo',
  hq: 'Sede',
  show: 'Mostrar',
  call: 'Llamada',
  text: 'Texto',
  send: 'Enviar',
  all_users: 'Todas las usuarias',
  frequently_used: 'Usado frecuentemente',
  by_user: 'Creado por {firstName} {lastName}',
  login: {
    phone: 'Teléfono',
    user_not_found: 'Usuario no encontrado',
    invalid_code: 'Código erróneo',
    enter_phone: 'Introduce tu número de móvil para iniciar sesión',
    send_to: 'Enviar código a:'
  },
  verify_code: {
    check_email: 'Enlace de inicio de sesión enviado a su correo',
    enter_code: 'Ingresa el código que te acabamos de enviar por mensaje de texto.',
    your_phone: 'Tu número de teléfono: %{phone}',
    didnt_recieve: '¿No recibiste un mensaje de texto? ',
    didnt_recieve_1: 'Enviar un correo electrónico',
    wrong_code: 'Codigo erroneo'
  },
  org_structure_jobs: {
    total_jobs: 'Puestos de trabajo disponibles',
    sunday_col: 'Dom',
    monday_col: 'Lun',
    tuesday_col: 'Mar',
    wednesday_col: 'Mié',
    thursday_col: 'Jue',
    friday_col: 'Vie',
    saturday_col: 'Sáb',
    reset_date: 'Hoy',
    delete_date: 'Eliminar fecha',
    delete_job_title: 'Confirmar eliminación del trabajo',
    delete_job_content: 'Se eliminarán todas las tareas relacionadas con este trabajo.',
    page_title: 'Plantillas de turnos',
    staff_page_title: 'Puesto de trabajo en la sede central',
    shift_duration: '%{hours}H %{minutes}M',
    shift_duration_hours: '%{hours}H',
    empty_text: 'No hay trabajos a la vista... ¡todavía!'
  },
  org_structure_providers: {
    total_providers: 'Total proveedores',
    page_title: 'Proveedores de servicio',
    no_providers: 'No hay proveedores a la vista... ¡todavía!'
  },
  devices: {
    general_name: 'General'
  },
  work_panel: {
    tasks_tab: 'Tareas',
    work_schedule: 'Turnos'
  },
  devices_faulty_device_dialog: {
    total_calls: 'Llamadas totales',
    off_time: 'Fuera de tiempo',
    total_cost: 'Coste total',
    open_date: 'Fecha abierta',
    whats_done: 'Descripción de la reparación',
    status: 'Estado',
    cost: 'Costo',
    close_date: 'Fecha Cerrada',
    close_ticket: 'Cerrar ticket',
    days_open: '{days_open} días',
    close_ticket_approve: 'El ticket se cerrará y pasará a la historia.',
    send: 'Enviar'
  },
  broken_item_dialog_dialog: {
    create_title: 'Nuevo ticket',
    update_title: 'Editar ticket',
    price: 'Costo',
    description: 'Describe el problema',
    progress_description: 'Describe el reparo',
    comment: 'Agregar comentario',
    status: 'Estado',
    status_open: 'Abrir',
    status_close: 'Cerrado',
    status_in_progress: 'En curso',
    status_done: 'Arreglado',
    priority: '¿Es urgente?',
    priority_low: 'Baja urgencia',
    priority_high: 'Alta urgencia',
    what_broke: '¿Que se rompió?',
    assigned_user: '¿Quien es responsable de esto?',
    fix_images: 'Imágenes de elementos fijos',
    add_invoice: 'Adjuntar una factura',
    deadline_date: 'Arreglar hasta la fecha',
    deadline_time: 'Arreglar hasta el tiempo',
    deadline_date_val: 'Reparar hasta: {deadlineDate}',
    add_video: 'Agregar video',
    open_days: 'Abrir {days} días',
    repair: 'Reparar',
    max_photo: 'Máximo 4',
    max_video: 'Máximo 1',
    add_service_provider: 'Seleccionar proveedor de servicios'
  },
  shifts: {
    morning: 'mañana',
    noon: 'mediodía',
    evening: 'tarde',
    night: 'noche',
    with_prefix: {
      morning: 'turno de mañana',
      noon: 'turno de mediodía',
      evening: 'turno de tarde',
      night: 'turno nocturno'
    }
  },
  org_job_new_dialog: {
    create_title: 'Agregar trabajo',
    update_title: 'Editar trabajo',
    job_name: 'Nombre de rol',
    choose_shift: 'turno',
    start: 'Iniciar',
    end: 'Fin',
    sun: 'Dom',
    mon: 'Lun',
    tue: 'Mar',
    wed: 'Mié',
    thu: 'Jue',
    fri: 'Vie',
    sat: 'Sáb',
    shift_times: 'horario de turnos',
    all_days: 'Cada día',
    add_department: 'Agregar nuevo departamento',
    work_days: 'Días de trabajo',
    finish: 'Finalizar',
    daily: 'Diario'
  },
  org_provider_new_dialog: {
    create_title: 'Añadir un nuevo proveedor de servicios',
    update_title: 'Editar proveedor de servicios',
    provider_name: 'Nombre del Servicio',
    provider_phone: 'Numero de telefono',
    contact_name: 'Nombre completo',
    comments: 'Comentario'
  },
  validator: {
    invlid_time: 'insertar tiempo válido',
    invlid_number: 'insertar un número válido',
    required: 'Insertar valor'
  },
  device_device_new_dialog: {
    create_title: 'Agregar objeto',
    create_for_category: 'Agregar objeto para la categoría',
    update_title: 'Actualizar objeto',
    device_department: 'Ubicación',
    name_taken_err: 'Nombre ya en uso'
  },
  confirm_delete: {
    title: 'Confirmar eliminación'
  },
  devices_new_operation: {
    op_desc: 'Escribe una tarea'
  },
  job_op_schedule: {
    choose_job: 'Seleccionar trabajo'
  },
  op_schedule_frequency_dialog: {
    placeholder: 'Frecuencia de repetición'
  },
  schedule_new_op_dialog: {
    title: 'Nueva Tarea',
    update_title: 'Actualizar tarea',
    copy_title: 'Copiar tarea',
    description: 'Escribe una tarea',
    choose_job: 'Seleccionar trabajo',
    set_account_manager: 'No hay roles de administrador definidos',
    no_account_selected: 'No hay cuentas seleccionadas',
    max_file_size: 'El tamaño máximo del archivo es 10 MB',
    x_jobs_selected: '{jobs_count} trabajos',
    related_item: 'Objeto asignado',
    new_item: 'Nombre del artículo',
    execution_time: 'Una hora para realizar',
    select_end_date: 'Puede seleccionar la fecha de finalización',
    tags: 'Seleccionar etiqueta',
    tag_report: 'Reportar',
    tag_monitor: 'Control',
    tag_procedure: 'Procedimiento',
    x_tags_selected: '{tags_count} etiquetas',
    select_color: 'Seleccionar color',
    require_image: 'Se requiere una imagen?',
    yearly_x_dates: 'Anual - {dateCount} fechas',
    yearly_single_date: 'Anual - {date}',
    exact_date_command: 'Puede elegir una fecha o un rango de fechas.',
    guildance_images: 'Imágenes de orientación',
    add_sub_task: 'Agregar subtareas',
    max_photo: 'Máximo 6',
    task_type: 'Tipo de tarea',
    task_type_regular: 'Tarea regular',
    task_type_image: 'Tarea de imagen',
    task_type_subtasks: 'Subtareas'
  },
  languages: {
    en: 'Inglés',
    he: 'עברית',
    es: 'Español',
    language: 'Idioma'
  },
  item_search: {
    placeholder: 'Buscar objeto',
    add_item: 'Agregar nuevo objeto',
    delete_title: 'Eliminar objeto',
    delete_content: 'Esto eliminaría todos las fallos leídos.'
  },
  provider_search: {
    placeholder: 'Buscar proveedor'
  },
  user_search: {
    placeholder: 'Buscar usuario',
    x_users_selected: '{usersCount} usuarios'
  },
  employees: {
    name_col: 'Nombre completo',
    add_employee_from_another_store: 'Agregar desde otra cuenta',
    total_employee: 'Total empleados',
    invite: 'Invitar',
    pending: 'Pendiente',
    page_title: 'Empleados',
    staff_page_title: 'Miembros del equipo de la sede central'
  },
  employees_dialog: {
    create_title: 'Añadir miembro del equipo',
    update_title: 'Editar Usuario',
    first_name: 'Nombre',
    last_name: 'Apellido',
    phone: 'Numero de teléfono',
    permission: 'Permiso',
    choose_jobs_placeholder: 'Elegir roles',
    birth_date: 'Cumpleaños',
    duplicate_phone: 'Ya existe un usuario con el mismo teléfono',
    user_role: 'Rol de usuario',
    send_invitation: 'Enviar invitación'
  },
  employees_other_store_dialog: {
    title: 'Agregar empleado de otra cuenta'
  },
  employees_roles: {
    company_admin: 'Administrador de la empresa',
    company_region_admin: 'Administrador de la región',
    account_admin: 'Gerente',
    account_manager: 'Departamento',
    account_employee: 'Empleado'
  },
  work_schedule_search_employee_dialog: {
    dialog_title: 'Horario para {jobShift} {jobName}',
    placeholder: 'Escriba el nombre del empleado',
    all_week_schedule: 'Toda la semana'
  },
  reported_item_op_list: {
    save_error: 'Se produjo un error al actualizar la lista.',
    title: 'Subtareas',
    comment: 'Escribe un comentario'
  },
  item_op_list_dialog: {
    title: 'Lista',
    feedback_description: 'Comentarios de tareas'
  },
  operation_frequency: {
    week_x_frequency: 'Una vez cada {frequency} semanas',
    week_x_frequency_with_day: 'Cada {frequency} semanas el {dayName}',
    exect_date_frequency: 'Fecha Específica',
    every_year_no_date: 'Anual',
    every_year_x_selected: '{datesCount} fechas seleccionadas',
    every_week: 'Una vez por semana',
    every_week_with_day: 'Cada {dayName}',
    every_day: 'Diario',
    every_year: 'Cada año en {date}',
    week_x_frequency_start_period: 'Operación abierta {day}, {date}',
    daily: 'Diario',
    weekly: 'Semanal',
    yearly: 'Anual',
    every_few_weeks: '¿Cada pocas semanas?',
    what_day: '¿Qué día?'
  },
  job_selection_search: {
    x_jobs_selected: '{job_count} roles'
  },
  account_selection_select: {
    placeholder: 'Seleccionar cuenta',
    x_accounts_selected: '{account_count} cuentas'
  },
  account_fault_devices: {
    faulty_device_tab: 'Tickets',
    faulty_device_history_tab: 'Historial',
    faulty_device_reports_tab: 'Informes',
    broken_items_assigned_to_me: 'Asignado a mí',
    broken_items_opened_by_me: 'Abierto por mí',
    broken_items_dates: 'Rango de fechas',
    open_date: 'fecha de creación',
    close_date: 'Fecha de cierre',
    general_search: 'Buscar por descripción',
    broken_items_settings: {
      anonymous_explain: 'Permita que usuarios externos creen tickets en su cuenta enviando un correo electrónico a una dirección de correo electrónico especial',
      anonymous_explain_field: 'Agregar nombre antes de @',
      invalid_subdomain: 'Dirección no válida, solo se permiten letras, números, "_" y "-"',
      page_title: 'Configuración de tickets',
      domain_taken: 'Esta dirección ya está ocupada.'
    }
  },
  account_selection: {
    staff_tasks: 'Sede',
    store_tasks: 'Cuenta',
    stores: 'Cuentas',
    my_tasks_tab: 'Personales',
    hq_tasks_tab: 'Sede',
    accounts_tasks_tab: 'Cuenta',
    empty_area: 'Sin área',
    select_area: 'Seleccionar área',
    x_selected_areas: '{area_count} áreas',
    no_area: 'Sin área',
    daily_progress: 'Progreso diario'
  },
  account_selection_employee: {
    select_account: 'Seleccionar cuenta'
  },
  install_prompt: {
    chrome: 'Añadir a la pantalla principal',
    ios: 'Instalar esta aplicación web: toque {shareIcon}',
    ios1: 'luego Agregar a la pantalla de inicio'
  },
  company_faulty_devices: {
    store_select: 'Seleccionar cuenta'
  },
  show_faulty_device: {
    empty: 'Sin dispositivos defectuosos'
  },
  company_settings: {
    company_name: 'Nombre de la empresa'
  },
  chat_dialog: {
    title: 'Chat',
    message_placeholder: 'Mensaje'
  },
  account_operations: {
    cancel_filter: 'Restablecer filtro',
    status: 'Estado',
    image: 'Imagen',
    check_list: 'Lista',
    status_done: 'Hecho',
    status_not_reported: 'No reportado',
    cross_account: 'Operaciones de Sede',
    with_image: 'Con imagen',
    without_image: 'Sin imagen',
    with_checklist: 'Con lista',
    job_list: 'Lista de trabajos',
    op_original_date: 'Fecha original: {date}',
    op_original_date_with_end_date: 'Fecha original: {date} - {endDate}',
    staff_operation: 'Operación Sede: {first_name} {last_name}',
    assigned_to: 'Asignado a: {executor}',
    mark_shift_completed: 'Turno reportado como completo',
    operation_from: 'De: {first_name} {last_name}',
    date_range_day: 'Día',
    date_range_week: 'Semana',
    date_range_month: 'Mes',
    done_by: 'hecho por {first_name} {last_name}',
    created_by: 'Creado por {firstName} {lastName}'
  },
  job_shift_select: {
    x_shifts_selected: '{shift_count} turnos'
  },
  version_check: {
    update_available: 'Hay una nueva versión disponible',
    reload: 'Actualizar'
  },
  duplicate_operation_dialog: {
    title: 'Copiar operación a otro rol'
  },
  color_picker: {
    clear: 'Limpiar'
  },
  out_of_shift_action: {
    title: 'La acción está fuera del horario del turno.',
    shift_not_start: 'Tu turno aún no ha comenzado',
    whice_shift: '¿Qué turno te gustaría ver?',
    title_before_start: 'El turno aún no ha comenzado<br>Esta hoja de trabajo pertenece a <span class="red--text font-weight-bold">{startDay}</span><br>para el turno que comienza a las <span class= "red--text font-weight-bold">{startTime}</span>',
    return_work_panel: 'Volver al panel de trabajo',
    dismiss: 'Vale, permanecer en este turno.',
    pass_shift: 'Cambiar al turno del día anterior'
  },
  edit_operation_dialog: {
    invalid_day: '{job_name} no trabaja el {día}'
  },
  additional_allowed_jobs: {
    title: 'Horario de trabajo para {jobName}'
  },
  account_edit_dialog: {
    title: 'Editar cuenta'
  },
  create_district_dialog: {
    title: 'Nueva área',
    update_title: 'Editar área',
    placeholder: 'Nombre del área'
  },
  staff_account_job_dialog: {
    title: 'Añadir un nuevo puesto de trabajo',
    title_for_update: 'Editar puesto de trabajo'
  },
  employee_schedules: {
    no_schedules: 'Sin horarios'
  },
  user_notifications: {
    missing_email: 'Proporcione el correo electrónico en la pestaña de registro de empleados.',
    sms: 'sms',
    email: 'correo electrónico',
    broken_item: {
      tab_title: 'Tickets',
      chat_notification: 'Notificaciones de chat',
      chat_notification_user_mentioned: 'Solo chats en los que se ha participado',
      low_priority: 'Nuevos tickets de baja urgencia',
      high_priority: 'Nuevos tickets de alta urgencia',
      deadline: 'Tickets abiertos en fecha límite',
      notify_not_only_assigned: 'Tickets no solo asignados a mi',
      status_change: 'Notificar cambio de estado'
    },
    job: {
      tab_title: 'Trabajos',
      tab_title_for_comapny_admins: 'Cuentas',
      chat_notification: 'Notificaciones de chat de tareas',
      chat_notification_cross_account: 'Sólo operaciones de Sede',
      cross_account_operation_notification: 'Notificar cuando se reciba la operación de Sede',
      birthdate_notification: 'Enviarme un correo electrónico cuando los empleados cumplan años',
      end_of_shift: 'Notificación de finalización de tareas de trabajo',
      uncompleted_shift_tasks: 'Notificar tareas incompletas para un turno de trabajo',
      task_create: 'Notificar cuando se crea una nueva tarea',
      task_daily: 'Notificaciones diarias de tareas pendientes'
    },
    staff_job: {
      tab_title: 'Staff Jobs',
      staff_task_daily: 'Notificaciones diarias de tareas pendientes',
      staff_task_create: 'Notificar cuando se crea una nueva tarea'
    },
    app_chat: {
      tab_title: 'Chat',
      join_group: 'Invitación de Grupo',
      new_message: 'Nuevos Mensajes'
    },
    communnity: {
      tab_title: 'Comunidad',
      new_post: 'Notificar cuando se cree una nueva publicación'
    },
    reports: {
      tab_title: 'Informes',
      daily_report: 'Enviar por correo electrónico el informe diario de operaciones'
    }
  },
  bottom_navigation: {
    work_schedule: 'Turnos',
    tasks: 'Tareas',
    faulty_device: 'Tickets',
    accounts: 'Cuentas',
    chat: 'Conversaciones'
  },
  work_schedule_request_statuses: {
    pending: 'Solicitud pendiente',
    approved: 'Solicitud aceptada',
    denied: 'Solicitud rechazada'
  },
  account_department_select: {
    placeholder: 'Elegir departamento',
    create: 'Crear departamento',
    update: 'Editar departamento',
    confirm_delete: '¿Desea eliminar el departamento {departmentName} de los roles?',
    error_name_taken: 'El nombre del departamento ya está en uso',
    x_selected: '{count} departamentos'
  },
  total_tasks_by_department: {
    empty_department: 'Sin Departamento',
    ongoing_html: 'En curso <span class="ongoing">{count}</span>',
    completed_html: 'Completado <span class="completed">{count}</span>'
  },
  menu_page: {
    profile: 'Perfil',
    accounts: 'Cuentas',
    title_general: 'General',
    title_preferences: 'Preferencias',
    title_admin: 'Admón',
    title_hq_admin: 'Administrador en Sede',
    tasks: 'Editar tareas',
    tasks_subtitle: 'Ver y editar todas las tareas',
    team: 'Miembros del equipo',
    team_subtitle: 'Agregar y editar miembros del equipo',
    team_subtitle_employee: 'Ver miembros del equipo',
    jobs: 'Plantillas de turnos',
    jobs_subtitle: 'Ver y editar trabajos',
    service_providers: 'Proveedor de servicios',
    service_providers_subtitle: 'Ver y editar proveedores de servicios de cuenta',
    company_details: 'Detalles de la empresa',
    general_settings: 'Permisos',
    hq_jobs: 'Trabajos de la sede',
    hq_members: 'Miembros del equipo de la Sede',
    hq_members_subtitle: 'Agregar y editar miembros del equipo de Sede',
    help: 'Ayuda',
    faults_settings: 'Configuración de tickets',
    task_bank: 'Banco de tarea',
    push_history: 'historial de notificaciones',
    drive: 'Archivos',
    hr_flow: 'Capacitación y formularios',
    logout: 'Cerrar sesión'
  },
  user_profile: {
    full_name: 'Nombre completo',
    phone: 'Número de teléfono',
    delete_account: 'Eliminar cuenta',
    delete_confirm: 'Esto eliminará permanentemente todos sus datos.',
    confirm: 'Confirmar',
    choose_avatar: 'Elegir avatar',
    jobs: 'Trabajos'
  },
  account_department_page: {
    total: 'Total departamentos',
    name_col: 'Nombre de Departamento',
    page_title: 'Departamentos'
  },
  app_chat: {
    create_group: 'Nuevo grupo',
    search_group_placeholder: 'Buscar usuario o grupo',
    search_group_divide: 'Grupos activos',
    search_user_divide: 'Otros usuarios',
    empty_messages: 'Ningún mensaje',
    group_details: 'Detalles del grupo',
    exit_group: 'Salir de grupo',
    delete_group: 'Eliminar grupo',
    delete_group_confirm: '¿Desea eliminar el grupo "{groupName}"?',
    delete_group_confirm_detail: 'Se eliminará todo el historial de mensajes.',
    exit_group_confirm: '¿Desea salir del grupo "{groupName}"',
    exit_group_confirm_detail: 'Se eliminará todo el historial de mensajes.',
    new_group_dialog: {
      title_create: 'Crear Nuevo Grupo',
      title_read_only: 'Detalles del grupo',
      title_update: 'Actualizar grupo',
      group_name: 'Nombre del grupo',
      group_image: 'Imagen de grupo',
      add_users: 'Agregar usuarios'
    },
    seen_by_dialog: {
      title: 'Info del mensaje',
      read_by: 'Leído por',
      pending: 'Pendiente'
    }
  },
  user_live_notifications: {
    new_group_html: "Te has unido al grupo de chat <span class='font-weight-bold'>{groupName}</span>"
  },
  language_select: {
    placeholder: 'Seleccionar idioma'
  },
  share_content: {
    clipboard_copy: 'Contenido copiado al portapapeles'
  },
  broken_item_share: {
    dialog_title: 'Compartir ticket',
    ticket_from: 'Fallo from: %{companyName} | %{accountName}',
    ticket_from_no_company: 'Fallo from: %{companyOrAccountName}',
    opened_at: 'Abierto en: %{open_date}',
    opened_by: 'Abierto por: %{open_by}',
    description: 'Descripción: %{description}',
    responsibility: 'Responsabilidad:',
    comment: 'Comentario: %{comment}',
    fix_description: 'Descripción de la solución: %{fix_description}',
    price: 'Precio: %{price}',
    fault_images: 'Imágenes de fallo',
    fault_fix_images: 'Imágenes de corrección de fallos',
    fault_attached_invoice: 'Factura de fallo',
    fault_attached_video: 'Vídeo de fallo',
    related_provider: 'Proveedor de servicio',
    related_providers: 'Proveedores de servicio:',
    fault_fix_until: 'Solucionar hasta: %{fixUntil}'
  },
  image_uploader: {
    max_images: 'Subir hasta %{maxImages} imágenes'
  },
  audio_recorder: {
    permission_denied: 'Permiso de audio denegado'
  },
  jobs_calander: {
    show_all: 'Mostrar todo (%{count})'
  },
  job_operation_edit: {
    delete_operation: 'Eliminar tarea'
  },
  feature_select: {
    enable_features: 'Permisos',
    broken_item_history: 'Panel de historial de tickets',
    broken_items: 'Tickets',
    read: 'Leer',
    edit: 'Editar',
    delete: 'Eliminar',
    create: 'Crear',
    close: 'Cerrar'
  },
  operation_share: {
    title: 'Compartir tarea',
    hq: 'Tarea de Sede',
    start_time: 'Hora de inicio a las: %{startTime}',
    creator: 'de: %{firstName} %{lastName}',
    not_reported: 'Estado: no terminado',
    reported: 'Estado: Hecho por %{firstName} %{lastName}',
    report_image: 'Reportar imagen:',
    attahced_file: 'Archivo adjunto:',
    checklist: 'Lista (%{done}/%{total}):',
    guidance_images: 'Imágenes de orientación:'
  },
  company_permissions: {
    create_object: 'Crear objeto',
    account_admin: 'Permiso de gerente',
    account_manager: 'Permiso del departamento',
    account_employee: 'Permiso de empleado'
  },
  cross_account_page: {
    my_operations: 'Tarea creada por mi',
    delete_cross_operation: 'Eliminar tarea',
    delete_cross_operation_detail: 'Esto eliminará todas las tareas relacionadas.'
  },
  single_task_notification: {
    title: 'Establecer alertas para la siguiente tarea',
    on_complete: 'Tarea completada',
    on_un_complete: 'Tarea incompleta'
  },
  install_app_prompt: {
    title: 'Hola, nuestra aplicación móvil está disponible en todas las tiendas.',
    close: 'Entendido, no volver a mostrar'
  },
  blog_post_create_dialog: {
    new_post_title: 'Nueva publicación',
    post_update_title: 'Actualizar publicación',
    new_comment_title: 'Responder a la publicación',
    comment_update_title: 'Actualizar comentario',
    content_placeholder: '¿De qué quieres hablar?',
    select_all: 'Seleccionar todas las cuentas',
    add_tag: 'Agregar étiqueta',
    post: 'Publicar'
  },
  tags_select: {
    placeholder: 'Elegir etiqueta'
  },
  blog_post: {
    read_more: 'Leer más',
    read_less: 'Leer menos',
    like: 'Me gusta',
    reply: 'Contestar',
    load_comments: 'Cargar comentarios',
    likes: 'Me Gustas',
    staff_account: 'Cuenta de personal',
    no_posts: 'Aún no hay publicaciones',
    be_first: '¡Sea el primero en publicar!',
    my_posts: 'Mis publicaciones',
    my_likes: 'Mis Me Gusta',
    actions: 'Acciones'
  },
  blog_post_share: {
    title: 'Compartir publicación',
    post_by: 'Publicado por: %{firstName} %{lastName}',
    created_at: 'Creado en: %{date}',
    post_tags: 'Etiquetas: %{tags}',
    images: 'Imágenes',
    video: 'vídeo: %{videoUrl}',
    likes_count: '%{likesCount} Me gusta',
    comments_count: '%{commentsCount} Comentarios'
  },
  video_upload: {
    max_size: 'El tamaño máximo del vídeo es 100 MB'
  },
  item_type_select: {
    title: 'Mostrar como título',
    single_checklist: 'Hecho/No Hecho',
    image: 'Informe con imagen',
    rating: 'Califica esta tarea',
    yes_no: 'Informe Si/No',
    feedback: 'Dejar comentarios',
    feedback_optional: '* Los comentarios son opcionales'
  },
  item_category_search: {
    placeholder: 'Buscar categoría',
    add_item_category: 'Agregar nueva categoría',
    delete_title: 'Eliminar categoría',
    delete_content: 'Esto eliminaría esta categoría de todos los objetos relacionados'
  },
  new_item_category: {
    create_title: 'Crear categoría',
    update_title: 'Actualizar categoría',
    name: 'Nombre'
  },
  company_broken_items: {
    deadline_date_label: 'Estado del SLA',
    deadline_date_passed: 'Fecha pasada',
    deadline_date_today: 'Arreglarlo hoy',
    deadline_date_coming_week: 'Arreglarlo esta semana',
    deadline_date_no_deadline: 'Sin fecha límite',
    no_deadline: 'Sin fecha límite',
    deadline_this_week: 'Arreglarlo esta semana',
    deadline_today: 'Arreglarlo hoy',
    deadline_passed: 'Fecha pasada',
    total: 'Tickets totales',
    avg_open_time: 'Tiempo de arreglo (días)',
    urgent_faults: 'Tickets urgentes',
    status: 'Estado',
    deadline: 'Fecha límite',
    after_deadline: 'después de la fecha límite',
    assigned_ticket: 'Tickets asignadas a otras',
    report: {
      export_excel: 'Exportar a Excel',
      send_later: 'Este archivo será enviado a tu correo electrónico.',
      missing_email: 'No hay ninguna dirección de correo electrónico para enviar el archivo.'
    }
  },
  broken_item: {
    created_by: 'Creado por:',
    assigned_to: 'Asignado a:',
    repair_until: 'Reparar hasta:',
    options: 'Opciones',
    close_ticket: 'Cerrar y enviar al historial',
    service_provider: 'Proveedor de servicio:',
    time_left: 'Tiempo restante'
  },
  district_select: {
    add_new: 'Agregar nueva área',
    delete_title: 'Eliminar área',
    delete_content: 'Esto eliminará el área.'
  },
  broken_item_table: {
    headers: {
      account_name: 'Cuenta',
      location: 'ubicación',
      assigned_to: 'Asignado a',
      service_provider: 'Proveedor de servicios',
      comments: 'Comments',
      open_date: 'Cita abierta',
      status: 'Estado'
    }
  },
  company_stats: {
    faults: 'Tickets'
  },
  company_table: {
    view: 'Ver',
    headers: {
      name: 'nombre de la cuenta',
      location: 'área',
      hq_tasks: 'Tareas de la sede',
      account_tasks: 'Tareas de cuenta',
      total_faults: 'tickets'
    }
  },
  items_page: {
    pick_account: 'Elegir cuenta',
    general: 'General',
    total_calls: 'Total de tickets',
    off_time: 'Fuera de tiempo',
    total_cost: 'Costo total'
  },
  tasks_page: {
    delete_task_image: '¿Estás seguro de que deseas eliminar esta imagen?',
    empty_tasks: 'No tienes tareas para hoy',
    jobs: 'trabajos',
    departments: 'Departamentos',
    progress: 'Progreso',
    more_details: 'Más detalles',
    less_details: 'Menos detalles'
  },
  task_bank: {
    title: 'Banco de tarea',
    every_day_frequency: 'Cada día',
    every_week_frequency: 'Cada semana',
    every_x_weeks_frequency: 'Cada X semanas',
    every_year_frequency: 'Cada año',
    exect_date_frequency: 'Fecha específica',
    no_tasks: 'No hay tareas',
    serach_placeholder: 'buscar por descripción de tarea',
    assigned_to_x_jobs: 'Asignado a {jobsCount} trabajos'
  },
  hq_task_job_group: {
    create_title: 'Crear nuevo grupo de trabajo',
    update_title: 'Actualizar grupo de trabajo',
    job_groups: 'Grupos de trabajo',
    delete_group: 'Delete {groupName} job group',
    group_name: 'Nombre del grupo'
  },
  default_deadline: {
    broken_item_settings: 'Configuración de fallos',
    high_priority: 'Alta urgencia tickets',
    low_priority: 'Baja urgencia tickets',
    deadline_time: 'Hora predeterminada para cerrar los fallos (horas)',
    deadline_notification: 'Enviar notificación en caso de que el fallo no sea resuelto después (hours)'
  },
  push_history: {
    title: 'historial de notificaciones',
    no_notifications: 'Sin notificaciones',
    is_viewed: 'Leyendo',
    viewed: 'Leído',
    not_viewed: 'No leído',
    mark_as_viewed: 'Marcar como visto',
    assigned_ticket: 'Ticket asignado a usted en %{accountName} para %{itemName}',
    ticket_new_message: 'Nuevo mensaje recibido para el ticket en %{accountName} para %{itemName}',
    new_ticket: 'Nuevo ticket creado en %{accountName} para %{itemName}',
    updated_ticket: 'Ticket actualizado en %{accountName} para %{itemName}',
    ticket_deadline: 'El ticket en %{accountName} para %{itemName} debe solucionarse antes del %{deadlineDate}',
    ticket_timed_notification: 'Notificación adicional para el ticket en %{accountName} para %{itemName} que aún está abierto desde %{openDate}',
    new_blog_post: 'Nueva entrada de blog creada por %{firstName} %{lastName}',
    new_app_chat_group_message: 'Nuevo mensaje de %{firstName} %{lastName} a las %{groupName}',
    new_app_chat_message: 'Nuevo mensaje de %{firstName} %{lastName}',
    app_chat_join_group: 'Te uniste al grupo de chat %{groupName}',
    task_chat_message: 'Nuevo mensaje recibido para la tarea en %{accountName} para %{jobName} %{jobShift}',
    new_staff_task: 'Nueva tarea personal creada por %{firstName} %{lastName} para %{jobName}',
    new_corss_account_task: 'Nueva tarea de HQ creada en %{accountName} para %{jobName} %{jobShift}',
    new_task: 'Nueva tarea creada en %{accountName} para %{jobName} %{jobShift}',
    task_not_completed: 'La tarea no se informó como realizada en %{accountName} para %{jobName} %{jobShift}',
    task_completed: 'La tarea se informó como realizada en %{accountName} para %{jobName} %{jobShift}',
    shift_completed: 'Se informó que el turno se realizó a las %{accountName} para %{jobName} %{jobShift}',
    shift_not_completed: 'Las tareas de turno no se realizaron en %{accountName} para la %{jobShift} de %{jobName}'
  },
  drive: {
    title: 'Archivos',
    upload: 'Subir',
    create_folder: 'Crear nueva carpeta',
    update_folder: 'Editar carpeta',
    folder_name: 'Nombre de la carpeta',
    all_accounts: 'Todas las cuentas',
    one_file: 'Un archivo',
    many_files: '{count} archivos',
    one_folder: 'Una carpeta',
    many_folders: '{count} carpetas',
    all_roles: 'Todos los permisos',
    delete_folder: 'Eliminar la carpeta {folderName}',
    delete_folder_details: 'Esto eliminaría todo el contenido de la carpeta.',
    upload_file: 'Subir archivo',
    update_file: 'Actualizar archivo',
    delete_file: 'Eliminar el archivo {fileName}',
    owner: 'Dueño',
    link_copied: 'Enlace copiado al portapapeles',
    owner_search: 'Creado por',
    empty_drive: 'No hay archivos ni carpetas',
    empty_folder: 'No hay archivos todavía',
    roles_access: 'Acceso a roles',
    cover_photo: 'Agregar Imagen',
    file_info: 'Información del archivo',
    downloaded_by: 'Descargado por',
    no_downloads: 'El archivo aún no se ha descargado',
    empty_single_folder: 'Carpeta vacía'
  },
  hq_tasks_page: {
    account_rating: 'Calificación de la cuenta',
    top_3: 'Los 3 mejores',
    bottom_3: 'Últimos 3',
    all_tasks: 'Todas las tareas',
    single_account_mode: 'Modo de cuenta única',
    hq_tasks: 'Tareas de Sede',
    created_by_me: 'Tarea creada por mi',
    alerted_tasks: 'Tareas con alertas',
    no_tasks: 'No hay tareas para hoy',
    update_every_hour: 'Se actualiza cada hora'
  },
  page_filter: {
    filter_by: 'Filtrar por'
  },
  task_order_dialog: {
    shift_title: 'Cambiar orden de tareas'
  },
  hr_flows: {
    create_flow: 'Crear proceso',
    process_name: 'Nombre del proceso',
    create_form: 'Crear formulario',
    form_name: 'Nombre del formulario',
    request_type: 'Tipo de solicitud',
    sign_file: 'Firmar archivo',
    attach_file: 'Adjuntar archivo',
    download: 'Descargar y leer',
    select_user: 'Seleccionar usuario',
    more_details: 'Más detalles'
  }
}
