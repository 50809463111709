import Client from '@/api_client/client.js'

const index = (apiKey, params) => {
  const INDEX_HR_FLOW = `/accounts/${apiKey}/hr_flows`
  return Client.get(INDEX_HR_FLOW, { params })
}

const create = (apiKey, params) => {
  const CREATE_HR_FLOW = `/accounts/${apiKey}/hr_flows`
  return Client.post(CREATE_HR_FLOW, params)
}

const destroy = (apiKey, hrFlowId) => {
  const HR_FLOW_DELETE = `/accounts/${apiKey}/hr_flows/${hrFlowId}`
  return Client.delete(HR_FLOW_DELETE)
}

export {
  index,
  create,
  destroy
}
