<template>
  <v-dialog
    v-model="dialog" width="540" persistent
    scrollable
    content-class="rounded-xl"
  >
    <new-hr-flow-step-dialog ref="newHrFlowStepDialogRef" />
    <v-card
      class="overflow-y-hidden popup-card bg-color17"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="black"
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ $t('hr_flows.create_flow') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2 mt-3">
        <v-row align="start" justify="center">
          <v-col cols="11">
            <v-text-field
              v-model="hrFlow.content"
              outlined
              background-color="white"
              class="required"
              counter="120"
              :label="$t('hr_flows.process_name')"
            />
          </v-col>
          <v-col cols="11" class="pt-0">
            <color-select
              v-model="hrFlow.color"
              @click:clear="clearColor"
            />
          </v-col>

          <draggable
            v-if="haveHrFlowSteps"
            :list="hrFlow.hrFlowSteps"
            class="col-11"
            tag="div"
            handle=".drag-handle"
          >
            <v-row
              v-for="(hrFlowStep, index) in hrFlow.hrFlowSteps" :key="index" align="center"
              justify="start" class="pa-0 ma-0"
            >
              <v-col class="flex-grow-0 flex-shrink-1 px-0">
                <v-btn text icon class="black--text drag-handle">
                  <v-icon>drag_indicator</v-icon>
                </v-btn>
              </v-col>
              <v-col
                class="flex-grow-1 flex-shrink-0 px-0 py-1 d-flex align-center justify-space-between"
              >
                <v-text-field
                  class="pointer"
                  :value="hrFlowStep.title"
                  readonly
                  outlined
                  background-color="white"
                  @click="onEditFlowStepClick"
                >
                  <template #prepend-inner>
                    <hr-flow-step-icon :step-type="hrFlowStep.stepType" />
                  </template>
                  <template #append>
                    <v-icon class="rtl-rotate">
                      chevron_right
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </draggable>
          <v-col cols="11">
            <v-text-field
              class="pointer"
              :value="$t('hr_flows.create_form')"
              readonly
              outlined
              :disabled="createFormDisabled"
              background-color="white"
              @click="onFlowStepClick"
            >
              <template #append>
                <v-icon class="rtl-rotate">
                  chevron_right
                </v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="11" class="pt-0">
            <v-select
              v-model="hrFlow.roles"
              class="role-select required"
              multiple
              single-line
              outlined
              background-color="white"
              item-text="translatedName"
              item-value="name"
              :items="rolesOptions"
              :menu-props="{ contentClass: isRtl ? 'rtl' : '' }"
              :label="$t('drive.roles_access')"
              :rules="[requiredField]"
              :disabled="isLoading || userSelect"
            >
              <template #item="{ item: role, attrs }">
                <v-row
                  no-gutters justify="start"
                  align="center"
                >
                  <v-col cols="10" class="font-weight-bold">
                    {{ role.translatedName }}
                  </v-col>
                  <v-col cols="2" class="texts-end">
                    <v-icon v-if="attrs.inputValue">
                      check_box
                    </v-icon>
                    <v-icon v-else>
                      check_box_outline_blank
                    </v-icon>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col cols="11" class="py-0">
            <account-select
              v-if="showAccountSelect"
              ref="accountSelectRef"
              v-model="hrFlow.accounts"
              multiple
              :api-key="apiKey"
              :dense="false"
              background-color="white"
              color="#E8E8E8"
              :disabled="isLoading || userSelect"
              show-select-all
            />
          </v-col>
          <v-col cols="11" class="py-0 px-5 d-flex align-center justify-space-between">
            <span class="color5 t-500-17">{{ $t('hr_flows.select_user') }}</span>
            <v-switch v-model="userSelect" class="ltr" />
          </v-col>
          <v-col v-if="userSelect" cols="11" class="pt-0">
            <company-user-search
              v-if="isCompanyAdminRegionOrAbove"
              v-model="hrFlow.users"
              :api-key="apiKey"
              outlined
              class="required"
              show-selection-count
              hide-details
              background-color="white"
              :placeholder="$t('app_chat.new_group_dialog.add_users')"
            />
            <user-search
              v-else
              v-model="hrFlow.users"
              class="required"
              :api-key="apiKey"
              cross-account-users
              multiple
              background-color="white"
              outlined
              chip-color="black white--text"
              :disabled="isLoading"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import NewHrFlowStepDialog from '@/components/hr_flows/NewHrFlowStepDialog.vue'
import HrFlowStepIcon from '@/components/hr_flows/HrFlowStepIcon.vue'
import ColorSelect from '@/components/shared/ColorSelect.vue'
import AccountSelect from '@/components/shared/AccountSelect.vue'
import UserSearch from '@/components/shared/user_search/UserSearch.vue'
import CompanyUserSearch from '@/components/shared/user_search/CompanyUserSearch.vue'

import { create as createHrFlow } from '@/api_client/HrFlow.js'
import { batchUpsert as batchUpsertHrFlowStep } from '@/api_client/HrFlowStep.js'
import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'

import useAuth from '@/composables/useAuth.js'
import useCompany from '@/composables/useCompany.js'

import { isString, isFile, isObject } from '@/helpers/Utils.js'

import { roleAndBelowRoles } from '@/store/modules/EmployeeRole.js'

import { requiredField } from '@/classes/InputValidators.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import draggable from 'vuedraggable'
import { isRtl } from '@/i18n'

import { computed, ref } from 'vue'

const maxHrFlowSteps = 5
const defaultHrFlow = () => ({
  content: null,
  color: null,
  hrFlowSteps: [],
  roles: [],
  accounts: [],
  users: []
})

export default {
  components: {
    'new-hr-flow-step-dialog': NewHrFlowStepDialog,
    'color-select': ColorSelect,
    'account-select': AccountSelect,
    'user-search': UserSearch,
    'company-user-search': CompanyUserSearch,
    'hr-flow-step-icon': HrFlowStepIcon,
    draggable
  },
  setup () {
    const dialog = ref(false)
    const accountSelectRef = ref(null)
    const newHrFlowStepDialogRef = ref(null)
    const isLoading = ref(false)
    const userSelect = ref(false)
    let resolveFunc = null
    const hrFlow = ref(defaultHrFlow())
    const { currentUserRole, isCompanyAdminRegionOrAbove, isUserCompanyAdmin, apiKey } = useAuth()
    const { isMultipleMemberships } = useCompany(apiKey.value)

    const rolesOptions = roleAndBelowRoles(currentUserRole.value)

    const apiParamsForHrFlow = () => {
      const result = {
        content: hrFlow.value.content,
        color: hrFlow.value.color
      }
      if (userSelect.value) {
        result.user_ids = hrFlow.value.users.map(user => user.id)
      } else {
        result.roles = hrFlow.value.roles
        if (isUserCompanyAdmin.value && accountSelectRef.value?.allSelected()) {
          result.belongs_to_all_accounts = true
        } else {
          result.api_keys = hrFlow.value.accounts
        }
      }

      return result
    }

    const hrFlowStepApiParams = () => {
      const steps = hrFlow.value.hrFlowSteps.map((hrFlowStep) => {
        const result = {
          id: hrFlowStep.id,
          step_type: hrFlowStep.stepType,
          title: hrFlowStep.title,
          description: hrFlowStep.description
        }
        if (isFile(hrFlowStep.file)) {
          result.file_name = hrFlowStep.file.name
        }
        return result
      })
      return { hr_flow_steps: steps }
    }

    const upsertHrStepFlow = (hrFlowId) => batchUpsertHrFlowStep({ apiKey: apiKey.value, hrFlowId, params: hrFlowStepApiParams() })

    const addHrStepFiles = (hrFlowStepResponse) => {
      const promises = []
      hrFlow.value.hrFlowSteps.forEach((hrFlowStep, index) => {
        if (!isFile(hrFlowStep.file)) return
        const fileUploadData = hrFlowStepResponse[index]?.file_upload_data
        if (!isObject(fileUploadData)) return

        promises.push(uploadFileToRemoteServer(hrFlowStep.file, fileUploadData.file_url))
      })
      return Promise.all(promises)
    }

    // Methods
    const clearColor = () => (hrFlow.value.color = null)
    const onEditFlowStepClick = () => {}
    const onFlowStepClick = () => newHrFlowStepDialogRef.value.openDialog()
      .then(({ isSaved, hrFlowStep }) => {
        if (!isSaved) return

        hrFlow.value.hrFlowSteps.push(hrFlowStep)
      })

    const openDialog = () => {
      dialog.value = true
      hrFlow.value = defaultHrFlow()
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const close = (isChanged) => {
      dialog.value = false
      resolveFunc(isChanged === true)
    }

    const save = () => {
      const params = apiParamsForHrFlow()
      isLoading.value = true
      createHrFlow(apiKey.value, params)
        .then(({ data: { id } }) => upsertHrStepFlow(id))
        .then(({ data: { data } }) => addHrStepFiles(data))
        .then(() => close(true))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    // Computed
    const haveHrFlowSteps = computed(() => hrFlow.value.hrFlowSteps.length > 0)
    const showAccountSelect = computed(() => isMultipleMemberships.value)
    const createFormDisabled = computed(() => hrFlow.value.hrFlowSteps.length >= maxHrFlowSteps)

    const isSaveDisabled = computed(() => {
      if (isLoading.value) return true
      const hrFlowVal = hrFlow.value
      const validContent = isString(hrFlowVal.content) && hrFlowVal.content.length > 0
      if (!validContent) return true
      if (hrFlowVal.hrFlowSteps.length === 0) return true
      if (userSelect.value) return hrFlowVal.users.length === 0

      if (hrFlowVal.roles.length === 0) return true
      if (hrFlowVal.belongsToAllAccounts) return false
      return hrFlowVal.accounts.length === 0
    })

    return {
      dialog,
      accountSelectRef,
      apiKey,
      userSelect,
      newHrFlowStepDialogRef,
      createFormDisabled,
      isRtl,
      isLoading,
      isSaveDisabled,
      hrFlow,
      haveHrFlowSteps,
      rolesOptions,
      showAccountSelect,
      isCompanyAdminRegionOrAbove,
      requiredField,
      openDialog,
      onFlowStepClick,
      onEditFlowStepClick,
      clearColor,
      close,
      save
    }
  }
}
</script>
