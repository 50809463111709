<template>
  <v-expansion-panels v-model="expanded" flat>
    <v-expansion-panel class="rounded-xl">
      <v-expansion-panel-header class="single-hr-flow elevation-0">
        <v-row align="center" justify="start">
          <v-col class="flex-grow-1 flex-shrink-0 d-flex align-center justify-start">
            <span :style="{ 'background-color': color }" class="flow-color">
              {{ hrFlow.name }}
            </span>
            <span class="t-700-16 color5 pps-2">
              {{ hrFlow.content }}
            </span>
          </v-col>
          <v-col class="flex-grow-0 flex-shrink-1 texts-end text-no-wrap">
            <span class="t-600-13 color21">
              {{ $t('hr_flows.more_details') }}
            </span>
            <v-icon class="collapse-icon" :class="{ rotated: isExpanded }">
              keyboard_arrow_down
            </v-icon>
            <v-menu v-if="showDeleteAndEditOptions" close-on-content-click>
              <template #activator="{ on }">
                <v-icon color="black" v-on="on">
                  more_vert
                </v-icon>
              </template>
              <v-list class="edit-operation">
                <v-list-item ripple @click="onEdit">
                  <v-list-item-icon>
                    <v-icon small>
                      edit
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-list-item ripple @click="onDelete">
                  <v-list-item-icon>
                    <v-icon small>
                      delete
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col
            v-for="hrFlowStep in hrFlowSteps"
            :key="hrFlowStep.id"
            class="texts-start py-1" cols="12"
          >
            <hr-flow-step-icon :step-type="hrFlowStep.step_type" />
            <span class="t-500-16 color5 mms-1">
              {{ hrFlowStep.title }}
            </span>
            <span
              class="assignments t-700-13 color20"
            >
              <span class="color1">0</span> / {{ totalAssignments }}
            </span>
          </v-col>
          <v-col cols="12" class="d-flex align-center justify-end">
            <v-icon color="#0000008a">
              group
            </v-icon>
            <span class="t-500-13 color5 mme-4 mms-1">
              {{ totalAssignments }}
            </span>
            <span class="t-500-13 color2">
              {{ $t('by_user', { firstName: hrFlow.creator?.first_name, lastName: hrFlow.creator?.last_name }) }}
              <span class="pps-1">
                ({{ createdAtString }})
              </span>
            </span>
          </v-col>
        </v-row>
        <template #actions>
          &nbsp;
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        Hello
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
import HrFlowStepIcon from '@/components/hr_flows/HrFlowStepIcon.vue'
import { isString } from '@/helpers/Utils.js'
import { dateToLocalString } from '@/helpers/DateTime.js'

import useAuth from '@/composables/useAuth.js'

import { computed, ref } from 'vue'
const defaultColor = '#E1CBF2'
export default {
  components: {
    'hr-flow-step-icon': HrFlowStepIcon
  },
  props: {
    hrFlow: {
      type: Object,
      required: true
    }
  },
  setup (props, { emit }) {
    const { isUserCompanyAdmin, currentUser } = useAuth()
    const expanded = ref(null)

    // Methods
    const onEdit = () => emit('edit', props.hrFlow)
    const onDelete = () => emit('delete', props.hrFlow)

    // Computed
    const isExpanded = computed(() => expanded.value === 0)
    const color = computed(() => isString(props.hrFlow.color) ? props.hrFlow.color : defaultColor)
    const createdAtString = computed(() => dateToLocalString(new Date(props.hrFlow.created_at)))
    const hrFlowSteps = computed(() => props.hrFlow.hr_flow_steps || [])
    const totalAssignments = computed(() => props.hrFlow.total_assignments || 0)
    const showDeleteAndEditOptions = computed(() => {
      if (isUserCompanyAdmin.value) return true
      const currentUserId = currentUser.value?.id
      return Number.isInteger(currentUserId) && currentUserId === props.hrFlow.creator?.user_id
    })

    return {
      color,
      isExpanded,
      expanded,
      showDeleteAndEditOptions,
      createdAtString,
      hrFlowSteps,
      totalAssignments,
      onEdit,
      onDelete
    }
  }
}
</script>
<style lang="scss">
.single-hr-flow {
  padding: 16px 8px;
  background-color: white;
  border-radius: 16px;
  box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.06), 0px 2px 6px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  .flow-color {
    border-radius: 50%;
    display: inline-block;
    width: 37px;
    height: 37px;
  }
  .collapse-icon {
    transition: transform 0.3s ease;
  }
  .rotated {
    transform: rotate(180deg);
  }
  .assignments {
    border-radius: 26px;
    background-color: #F4F4F4;
    padding: 2px 6px;
  }
}
</style>
