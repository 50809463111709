<template>
  <v-dialog
    v-model="dialog" max-width="500" persistent
    content-class="rounded-xl"
  >
    <v-card
      class="edit-folder-dialog"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title>
        <v-row align="center" justify="start">
          <v-col cols="2" class="texts-start">
            <v-btn
              fab small depressed
              text :disabled="isLoading"
              :loading="isLoading" @click="close"
            >
              <v-icon>
                close
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center t-800-17">
            {{ dialogTitle }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2 bg-color17">
        <v-row
          v-if="isLoadingFolder" align="center" justify="center"
          class="my-5"
        >
          <v-progress-circular indeterminate color="primary" />
        </v-row>
        <v-row
          v-else align="center" justify="start"
          class="px-6"
        >
          <v-col cols="12" class="pb-0 mt-3">
            <v-text-field
              v-model="folder.name"
              single-line
              outlined
              class="required"
              background-color="white"
              color="#E8E8E8"
              :rules="[requiredField]"
              :loading="isLoading"
              :disabled="isLoading"
              counter="256"
              :label="$t('drive.folder_name')"
            />
          </v-col>
          <v-col v-if="!isSubFolder" cols="12" class="py-0">
            <div class="color5 t-500-16 mb-2">
              {{ $t('drive.cover_photo') }}
            </div>
            <single-image-uploader ref="folderImage" width="90px" />
          </v-col>
          <v-col v-if="!isSubFolder" cols="12" class="pb-0 mt-1">
            <v-select
              v-model="folder.roles"
              class="role-select required"
              multiple
              single-line
              outlined
              background-color="white"
              color="#E8E8E8"
              item-text="translatedName"
              item-value="name"
              :items="rolesOptions"
              :menu-props="{ contentClass: isRtl ? 'rtl' : '' }"
              :label="$t('drive.roles_access')"
              :rules="[requiredField]"
              @change="onRoleChange"
            >
              <template #item="{ item: role, attrs }">
                <v-row
                  no-gutters justify="start"
                  align="center"
                >
                  <v-col cols="10" class="font-weight-bold">
                    {{ role.translatedName }}
                  </v-col>
                  <v-col cols="2" class="texts-end">
                    <v-icon v-if="attrs.inputValue">
                      check_box
                    </v-icon>
                    <v-icon v-else>
                      check_box_outline_blank
                    </v-icon>
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="!isSubFolder" cols="12" class="pt-0">
            <v-switch
              v-if="showAllAccountsSelect" v-model="folder.allAccounts"
              :disabled="isLoading || isCompanyAdminFolder" class="d-inline-block ltr mt-0 pt-0"
              :label="$t('drive.all_accounts')"
              @change="onAllAccountsChange"
            />
            <account-select
              v-if="showAccountSelect"
              v-model="folder.accounts"
              multiple
              :api-key="apiKey"
              :dense="false"
              background-color="white"
              color="#E8E8E8"
              :disabled="isLoading || folder.allAccounts || isCompanyAdminFolder"
              :show-select-all="false"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-2 pb-4 bg-color17">
        <v-row align="center" justify="center">
          <v-col cols="5">
            <v-btn
              block
              color="black white--text" depressed
              class="save-btn"
              :disabled="saveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import AccountSelect from '@/components/shared/AccountSelect.vue'
import SingleImageUploader from '@/components/shared/SingleImageUploader.vue'

import { requiredField } from '@/classes/InputValidators.js'
import { isString } from '@/helpers/Utils.js'
import { roleAndBelowRoles } from '@/store/modules/EmployeeRole.js'

import {
  show as showDriveFolder, create as createDriveFolder,
  update as updateDriveFolder,
  removeImage as removeDriveFolderImage
} from '@/api_client/DriveFolder.js'
import { uploadFile as uploadFileToRemoteServer } from '@/general_client/FileUploader.js'
import { handler as errHandler } from '@/classes/ErrorHandler.js'

import useCompany from '@/composables/useCompany.js'
import useAuth from '@/composables/useAuth.js'

import { isRtl, i18n } from '@/i18n.js'

import { ref, computed } from 'vue'
const DialogModes = { create: 1, update: 2 }
const defaultFolderParams = () => ({ id: null, name: '', allAccounts: false, roles: [], accounts: [] })
export default {
  components: {
    'account-select': AccountSelect,
    'single-image-uploader': SingleImageUploader
  },
  props: {
    apiKey: {
      type: String,
      required: true
    },
    parentFolderId: {
      type: [Number, String],
      default: null
    }
  },
  setup (props) {
    const { isUserCompanyAdmin, currentUserRole } = useAuth()
    const { isMultipleMemberships } = useCompany(props.apiKey)
    const dialog = ref(false)
    const isLoading = ref(false)
    const dialogMode = ref()
    const folder = ref(defaultFolderParams())
    const rolesOptions = roleAndBelowRoles(currentUserRole.value)
    const isLoadingFolder = ref(false)
    const folderImage = ref(null)
    let resolveFunc = null

    const apiParams = () => {
      const folderVal = folder.value
      const result = {
        name: folderVal.name
      }

      if (isSubFolder.value) {
        result.parent_folder_id = props.parentFolderId
      } else {
        result.roles = folderVal.roles
        result.belongs_to_all_accounts = showAllAccountsSelect.value ? folderVal.allAccounts : false
        result.api_keys = showAccountSelect.value ? folderVal.accounts : props.apiKey
      }

      if (!isSubFolder.value && folderImage.value.shouldAddImage()) {
        result.with_image = true
        result.image_v2 = true
      }
      return result
    }

    const loadFolder = async (folderId) => {
      isLoadingFolder.value = true
      const loadedFolder = (await showDriveFolder(props.apiKey, folderId)).data.data
      const folderVal = folder.value
      folderVal.id = loadedFolder.id
      folderVal.name = loadedFolder.name
      folderVal.image_url = loadedFolder.image_url
      if (!isSubFolder.value) {
        if (loadedFolder.belongs_to_all_roles) {
          folderVal.roles = rolesOptions.map(role => role.name)
        } else {
          folderVal.roles = loadedFolder.roles
        }
        if (loadedFolder.belongs_to_all_accounts) {
          folderVal.allAccounts = loadedFolder.belongs_to_all_accounts
        } else {
          folderVal.accounts = loadedFolder.accounts.map(account => account.api_key)
        }
      }
      isLoadingFolder.value = false
    }

    const updateImageIfNeeded = async (responseData) => {
      if (isSubFolder.value) return
      if (folderImage.value.shouldRemoveImage()) {
        return removeDriveFolderImage({ apiKey: props.apiKey, id: folder.value.id })
      } else if (folderImage.value.shouldAddImage()) {
        const imageUploadData = responseData.image_upload_data
        await uploadFileToRemoteServer(folderImage.value.getImageFile(), imageUploadData.file_url)
      }
    }

    // Methods
    const onRoleChange = () => {
      if (!isCompanyAdminFolder.value) return
      folder.value.allAccounts = true
    }
    const openDialog = (mode = DialogModes.create) => {
      dialog.value = true
      dialogMode.value = mode
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const openForUpdate = async (folderId) => {
      await loadFolder(folderId)
      const imageUrl = folder.value.image_url
      if (folderImage.value) {
        folderImage.value.setImageUrl(imageUrl)
      } else {
        setTimeout(() => folderImage.value.setImageUrl(imageUrl))
      }
      return openDialog(DialogModes.update)
    }

    const onAllAccountsChange = (newAllAccounts) => {
      if (!newAllAccounts) return
      folder.value.accounts = []
    }

    const save = () => {
      isLoading.value = true
      const saveParams = apiParams()
      const apiCall = dialogMode.value === DialogModes.create
        ? createDriveFolder(props.apiKey, saveParams)
        : updateDriveFolder({ apiKey: props.apiKey, id: folder.value.id, params: saveParams })
      apiCall
        .then(({ data }) => updateImageIfNeeded(data))
        .then(() => close(true))
        .catch(errHandler)
        .finally(() => (isLoading.value = false))
    }

    const close = (isChanged = false) => {
      dialog.value = false
      isLoading.value = false
      folder.value = defaultFolderParams()
      isLoadingFolder.value = false
      if (folderImage.value) folderImage.value.reset()
      const result = { isChanged: isChanged === true }
      resolveFunc(result)
    }

    // Computed
    const isSubFolder = computed(() => {
      const id = Number(props.parentFolderId)
      return Number.isInteger(id) && id > 0
    })

    const isCompanyAdminFolder = computed(() => isUserCompanyAdmin.value &&
      folder.value.roles.length === 1 &&
      folder.value.roles[0] === 'company_admin')

    const dialogTitle = computed(() => dialogMode.value === DialogModes.create
      ? i18n.t('drive.create_folder')
      : i18n.t('drive.update_folder')
    )
    const showAccountSelect = computed(() => isMultipleMemberships.value)
    const showAllAccountsSelect = computed(() => isUserCompanyAdmin.value)

    const saveDisabled = computed(() => {
      if (isLoading.value) return true
      const folderVal = folder.value
      const folderName = folderVal.name
      if (!isString(folderName) || folderName.trim().length === 0) return true
      if (isSubFolder.value) return false
      if (!folderVal.belongs_to_all_roles && (folderVal.roles || []).length === 0) return true
      if (showAccountSelect.value && folderVal.accounts.length === 0 && !folderVal.allAccounts) return true

      return false
    })
    return {
      isRtl,
      dialog,
      dialogTitle,
      folder,
      isLoading,
      isLoadingFolder,
      folderImage,
      saveDisabled,
      rolesOptions,
      showAccountSelect,
      showAllAccountsSelect,
      isSubFolder,
      isCompanyAdminFolder,
      requiredField,
      onRoleChange,
      openDialog,
      openForUpdate,
      onAllAccountsChange,
      save,
      close
    }
  }
}
</script>
<style lang="scss">
.edit-folder-dialog {
  .role-select {
    .v-text-field__details {
      margin-bottom: 0;
    }
  }
  .save-btn.v-btn--disabled {
    color: white !important
  }
}

</style>
