<template>
  <v-icon color="#616365" class="material-icons-outlined">
    {{ icon }}
  </v-icon>
</template>
<script>
import { computed } from 'vue'
const hrStepTypeToIcon = {
  sign_file: 'edit',
  send_file: 'attach_file',
  read_file: 'download'
}
export default {
  props: {
    stepType: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const icon = computed(() => hrStepTypeToIcon[props.stepType])
    return {
      icon
    }
  }
}
</script>
