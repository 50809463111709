<template>
  <v-dialog
    v-model="dialog" width="540" persistent
    scrollable
    content-class="rounded-xl"
  >
    <v-card
      class="overflow-y-hidden popup-card bg-color17"
      :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
    >
      <v-card-title class="white px-1">
        <v-row align="center" justify="start" no-gutters>
          <v-col cols="2" class="texts-start">
            <v-btn
              icon
              color="white"
              @click="close"
            >
              <v-icon color="black">
                close
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="text-center color5 t-600-18">
            {{ $t('hr_flows.create_form') }}
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-2 mt-3">
        <v-row align="start" justify="center">
          <v-col cols="11">
            <v-text-field
              v-model="hrFlowStep.title"
              outlined
              background-color="white"
              class="required"
              counter="120"
              :label="$t('hr_flows.form_name')"
            />
          </v-col>
          <v-col cols="11">
            <v-radio-group
              v-model="hrFlowStep.stepType"
              class="mt-0" hide-details
            >
              <v-row
                align="start" justify="start" class="white rounded color5 t-400-16 py-2"
              >
                <v-col cols="12" class="color2">
                  {{ $t('hr_flows.request_type') }}
                </v-col>
                <v-col cols="11" class="color5 pointer texts-start" @click="onRowClick('sign_file')">
                  <v-icon class="ppe-1 material-icons-outlined">
                    edit
                  </v-icon>
                  {{ $t('hr_flows.sign_file') }}
                </v-col>
                <v-col cols="1" class="texts-end px-0">
                  <v-radio value="sign_file" />
                </v-col>
                <v-col cols="11" class="color5 pointer texts-start" @click="onRowClick('send_file')">
                  <v-icon class="ppe-1 material-icons-outlined">
                    attach_file
                  </v-icon>
                  {{ $t('hr_flows.attach_file') }}
                </v-col>
                <v-col cols="1" class="texts-end px-0">
                  <v-radio value="send_file" />
                </v-col>
                <v-col cols="11" class="color5 pointer texts-start" @click="onRowClick('read_file')">
                  <v-icon class="ppe-1 material-icons-outlined">
                    download
                  </v-icon>
                  {{ $t('hr_flows.download') }}
                </v-col>
                <v-col cols="1" class="texts-end px-0">
                  <v-radio value="read_file" />
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
          <v-col cols="11" class="pb-0">
            <v-textarea
              v-model="hrFlowStep.description"
              auto-grow
              rows="4"
              single-line
              background-color="white"
              outlined
              counter="200"
              label="Add comment"
            />
          </v-col>
          <v-col v-if="showAddFile" cols="11" class="pt-0">
            <v-file-input
              v-model="hrFlowStep.file"
              class="pt-0 d-inline-flex flex-grow-0"
              :placeholder="$t('hr_flows.attach_file')"
              accept="*/*" prepend-icon="attach_file"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-btn
              color="black" class="save-btn white--text" block
              depressed tile
              :loading="isLoading"
              :disabled="isSaveDisabled"
              @click="save"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { isString, isFile } from '@/helpers/Utils.js'
import { isRtl } from '@/i18n'

import { computed, ref } from 'vue'

const defaultHrFlowStep = () => ({
  title: null,
  description: null,
  stepType: 'sign_file',
  file: null
})
export default {
  setup () {
    let resolveFunc = null
    const dialog = ref(false)
    const isLoading = ref(false)
    const hrFlowStep = ref(defaultHrFlowStep())
    // Methods
    const onRowClick = (value) => (hrFlowStep.value.stepType = value)
    const openDialog = () => {
      dialog.value = true
      hrFlowStep.value = defaultHrFlowStep()
      return new Promise((resolve, reject) => {
        resolveFunc = resolve
      })
    }

    const close = (isSaved) => {
      dialog.value = false
      const result = JSON.parse(JSON.stringify(hrFlowStep.value))
      result.file = hrFlowStep.value.file
      resolveFunc({
        isSaved: isSaved === true,
        hrFlowStep: result
      })
    }

    const save = () => close(true)

    // Computed
    const showAddFile = computed(() => hrFlowStep.value.stepType !== 'send_file')

    const isSaveDisabled = computed(() => {
      if (isLoading.value) return true
      const validTitle = isString(hrFlowStep.value.title) && hrFlowStep.value.title.length > 0
      if (!validTitle) return true
      if (showAddFile.value && !isFile(hrFlowStep.value.file)) return true
      return false
    })
    return {
      dialog,
      isRtl,
      isLoading,
      isSaveDisabled,
      hrFlowStep,
      showAddFile,
      openDialog,
      onRowClick,
      close,
      save
    }
  }
}
</script>
