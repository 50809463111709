<template>
  <v-row
    align="start" justify="start" class="mt-15 mx-md-8"
    :class="{ 'rtl': isRtl, 'ltr': !isRtl }"
  >
    <new-hr-flow-dialog ref="newHrFlowDialogRef" />
    <confirm-delete ref="deleteDialogRef" />
    <v-col cols="12" class="mt-10 texts-end">
      <v-btn
        color="#CFD9F5"
        elevation="0" class="rounded-lg"
        @click="onCreateFlowClick"
      >
        <v-icon>
          add
        </v-icon>
        <span class="color20 t-500-16">
          {{ $t('hr_flows.create_flow') }}
        </span>
      </v-btn>
    </v-col>
    <v-col cols="12">
      <hr-flow-list
        :api-key="apiKey"
        @edit="onEdit"
        @delete="onDelete"
      />
    </v-col>
  </v-row>
</template>
<script>
import HrFlowList from '@/components/hr_flows/HrFlowList.vue'
import NewHrFlowDialog from '@/components/hr_flows/NewHrFlowDialog.vue'
import ConfirmDelete from '@/components/shared/ConfirmDelete.vue'

import useHrFlow from '@/composables/useHrFlow.js'
import useAuth from '@/composables/useAuth.js'

import { handler as errHandler } from '@/classes/ErrorHandler.js'

import { isRtl } from '@/i18n.js'

import { ref } from 'vue'
export default {
  components: {
    'hr-flow-list': HrFlowList,
    'new-hr-flow-dialog': NewHrFlowDialog,
    'confirm-delete': ConfirmDelete
  },
  setup () {
    const newHrFlowDialogRef = ref(null)
    const deleteDialogRef = ref(null)
    const { apiKey } = useAuth()

    const { deleteHrFlow, loadHrFlows } = useHrFlow(apiKey.value)
    // Methods
    const onCreateFlowClick = () => newHrFlowDialogRef.value.openDialog()
      .then((isChanged) => {
        if (!isChanged) return
        loadHrFlows({ reset: true })
      })
    const onEdit = (hrFlow) => newHrFlowDialogRef.value.openDialog(hrFlow)
    const onDelete = (hrFlow) => deleteDialogRef.value.open()
      .then((shouldDelete) => {
        if (!shouldDelete) return
        deleteHrFlow(hrFlow)
          .then(() => deleteDialogRef.value.close())
          .catch(errHandler)
      })

    return {
      deleteDialogRef,
      newHrFlowDialogRef,
      isRtl,
      apiKey,
      onCreateFlowClick,
      onEdit,
      onDelete
    }
  }
}
</script>
